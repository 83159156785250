import { CodeName } from "@/store/common";

//倉庫・商品
export type CenterItem = {
  itemCD?: string | null, //商品CD
  itemNM?: string | null, //商品名
  janCD?: string | null,   //JAN
  makerCD?: string | null, //メーカー
  makerNM?: string | null, //メーカー
  categoryCD?: string | null, //ｶﾃｺﾞﾘ
  categoryNM?: string | null, //ｶﾃｺﾞﾘ
  pbKbnCD?: string | null,
  pbKbnNM?: string | null,
  blIrisu?: number | null,  //容量1(BL入数) 
  csIrisu?: number | null,  //容量2(CS入数)
  capacity?: string | null, //内容量
  bestBefore?: number | null, //賞味期間
  tolerance?: number | null, //許容期間
  teika?: number | null, //定価
  tatene?: number | null, //建値

  bumonCD?: string, //--部門
  bumonNM?: string | null, //部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerNM?: string | null, //倉庫
  centerDiv?: 'TC' | 'DC' | null, //TC/DC

  mainloca?: string | null, //代表ロケ
  tanaban?: string | null, //棚番
  tanabanWms?: string | null, //棚番　厚木のみ有効（出切・返品を除外）
  zaikoQtyBl1?: number | null, //在庫数1.一般
  zaikoQtyBl2?: number | null, //在庫数2.特売
  zaikoQtyBl3?: number | null, //在庫数3.予約
  zaikoQtyBl4?: number | null, //在庫数4.出切
  zaikoQtyBl5?: number | null, //在庫数5.返品
  zaikoQtyPs1?: number | null, //在庫数1.一般
  zaikoQtyPs2?: number | null, //在庫数2.特売
  zaikoQtyPs3?: number | null, //在庫数3.予約
  zaikoQtyPs4?: number | null, //在庫数4.出切
  zaikoQtyPs5?: number | null, //在庫数5.返品

  nohinbi?: string, //最終納品日

  teibanNum?: number,
  teibanNMs?: string,
  sendoKyoyo?: number, //得意先商品マスタの許容日数
}
//出荷情報
export type SyukkaData = {
  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫

	nohinbi?: string, //最終納品日
  tokuisakiNum?: number,
  tokuisakiNMs?: string,
}
//賞味期限情報
export type KigenData = {
  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫
  irisu?: number | null,

	zaikoKbn?: '1'|'2'|'3'|'4'|'5'|'all'|string, //1.一般', '2.特売', '3.予約', '4.出切', '5.返品
  nyukaDate?: string | null,
  keikaDays?: number | null,
  zaikoQtyBl?: number | null,
  zaikoQtyPs?: number | null,
  zaikoQtyPsTotal?: number | null,
  zaikoAmtPsTotal?: number | null,
  nohinAbleDate2_3?: string | null,
  kigenbi?: string | null,
  kigenDate2_3?: string | null,
  nohinAbleDate1_2?: string | null,
  kigenDate1_2?: string | null,
  check?: string | null,
  kigenDateReal?: string | null,
  zaikoQtyBlReal?: string | null,
  
  nyukaDateInvalid?: boolean | null,
  kigenDateInvalid2_3?: boolean | null,
  kigenDateInvalid1_2?: boolean | null,
  kigenDateOver2_3?: boolean | null,
  kigenDateOver1_2?: boolean | null,
  kigenDateError2_3?: boolean | null,
  kigenDateError1_2?: boolean | null,
}

//履歴データ
export type HistoryData = {
  uketsukebi?: string | null,
  nyukosu?: number | null,
  syukkobi?: string | null,
  syukkosu?: number | null,
  gyomu?: string | null,
  kino?: string | null,
  note?: string | null,
  kigenbi?: string | null,
}

//棚卸情報
export type TanaoroshiData = {
  zikkbn?: '1' | '4'| '5' | string | null, 
  jan?: string | null, 
  item_cd?: string | null, 
  item_nm?: string | null, 
  maker_cd?: string | null, 
  maker_nm?: string | null, 
  mainloca?: string | null, 
  mainloca_1?: string | null, 
  mainloca_2?: string | null, 
  loca?: string | null, 
  loca_1?: string | null, 
  loca_2?: string | null, 
  bl_irisu?: number | null,
  cs_irisu?: number | null,
  zaiko_bl_ttl?: number | null,
  zaiko_br_ttl?: number | null,
  zaiko_bl?: number | null,
  zaiko_br?: number | null,
  kigenbi?: string | null, 
}

export const centerItemAttributes:CodeName[][] = [
];

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"", group_name:"【小計なし】", code: "itemCD_centerCD", name: "【小計なし】商品コード → 倉庫コード順", option1: "itemCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "itemNM_centerCD", name: "【小計なし】商品名 → 倉庫コード順", option1: "itemNM", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "janCD_centerCD", name: "【小計なし】JANコード → 倉庫コード順", option1: "janCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "maker_centerCD", name: "【小計なし】メーカー → 倉庫コード順", option1: "makerCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "category_centerCD", name: "【小計なし】商品カテゴリ → 倉庫コード順", option1: "categoryCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "bestBefore_centerCD", name: "【小計なし】賞味期間 → 倉庫コード順", option1: "bestBefore", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "tolerance_centerCD", name: "【小計なし】許容期間 → 倉庫コード順", option1: "tolerance", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "teika_centerCD", name: "【小計なし】定価 → 倉庫コード順", option1: "teika", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "tatene_centerCD", name: "【小計なし】建値 → 倉庫コード順", option1: "tatene", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "centerCD_itemCD", name: "【小計なし】倉庫コード → 商品コード順", option1: "centerCD", option2:"centerCD", option3: "itemCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "centerNM_itemCD", name: "【小計なし】倉庫名 → 商品コード順", option1: "centerNM", option2:"centerCD", option3: "itemCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "tanaban_itemCD_centerCD", name: "【小計なし】棚番 → 商品・倉庫コード順", option1: "tanaban", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"", group_name:"【小計なし】", code: "kigenDate2_3_itemCD_centerCD", name: "【小計なし】賞味期限 → 商品・倉庫コード順", option1: "kigenDate2_3", option2:"itemCD", option3: "centerCD", option4: ""}),
  
  new CodeName({group_code:"maker", group_name:"【メーカー小計】", code: "makerG_itemCD_centerCD", name: "【メーカー小計】商品コード → 倉庫コード順", option1: "makerCD", option2: "itemCD", option3:"itemCD", option4: "centerCD"}),
  new CodeName({group_code:"maker", group_name:"【メーカー小計】", code: "makerG_itemNM_centerCD", name: "【メーカー小計】商品名 → 倉庫コード順", option1: "makerCD", option2: "itemNM", option3:"itemCD", option4: "centerCD"}),
  new CodeName({group_code:"maker", group_name:"【メーカー小計】", code: "makerG_janCD_centerCD", name: "【メーカー小計】JANコード → 倉庫コード順", option1: "makerCD", option2: "janCD", option3:"itemCD", option4: "centerCD"}),
  new CodeName({group_code:"maker", group_name:"【メーカー小計】", code: "makerG_category_centerCD", name: "【メーカー小計】商品カテゴリ → 倉庫コード順", option1: "makerCD", option2: "categoryCD", option3:"itemCD", option4: "centerCD"}),
  new CodeName({group_code:"maker", group_name:"【メーカー小計】", code: "makerG_bestBefore_centerCD", name: "【メーカー小計】賞味期間 → 倉庫コード順", option1: "makerCD", option2: "bestBefore", option3:"itemCD", option4: "centerCD"}),
  new CodeName({group_code:"maker", group_name:"【メーカー小計】", code: "makerG_tolerance_centerCD", name: "【メーカー小計】許容期間 → 倉庫コード順", option1: "makerCD", option2: "tolerance", option3:"itemCD", option4: "centerCD"}),
  new CodeName({group_code:"maker", group_name:"【メーカー小計】", code: "makerG_teika_centerCD", name: "【メーカー小計】定価 → 倉庫コード順", option1: "makerCD", option2: "teika", option3:"itemCD", option4: "centerCD"}),
  new CodeName({group_code:"maker", group_name:"【メーカー小計】", code: "makerG_tatene_centerCD", name: "【メーカー小計】建値 → 倉庫コード順", option1: "makerCD", option2: "tatene", option3:"itemCD", option4: "centerCD"}),

  new CodeName({group_code:"item", group_name:"【商品小計】", code: "itemG_itemCD_centerCD", name: "【商品小計】商品コード → 倉庫コード順", option1: "itemCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item", group_name:"【商品小計】", code: "itemG_itemNM_centerCD", name: "【商品小計】商品名 → 倉庫コード順", option1: "itemNM", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item", group_name:"【商品小計】", code: "itemG_janCD_centerCD", name: "【商品小計】JANコード → 倉庫コード順", option1: "janCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item", group_name:"【商品小計】", code: "itemG_maker_centerCD", name: "【商品小計】メーカー → 倉庫コード順", option1: "makerCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item", group_name:"【商品小計】", code: "itemG_category_centerCD", name: "【商品小計】商品カテゴリ → 倉庫コード順", option1: "categoryCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item", group_name:"【商品小計】", code: "itemG_bestBefore_centerCD", name: "【商品小計】賞味期間 → 倉庫コード順", option1: "bestBefore", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item", group_name:"【商品小計】", code: "itemG_tolerance_centerCD", name: "【商品小計】許容期間 → 倉庫コード順", option1: "tolerance", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item", group_name:"【商品小計】", code: "itemG_teika_centerCD", name: "【商品小計】定価 → 倉庫コード順", option1: "teika", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item", group_name:"【商品小計】", code: "itemG_tatene_centerCD", name: "【商品小計】建値 → 倉庫コード順", option1: "tatene", option2:"itemCD", option3: "centerCD", option4: ""}),

  new CodeName({group_code:"center", group_name:"【センター小計】", code: "centerG_centerCD_itemCD", name: "【センター小計】倉庫コード → 商品コード順", option1: "centerCD", option2:"centerCD", option3: "itemCD", option4: ""}),
  new CodeName({group_code:"center", group_name:"【センター小計】", code: "centerG_centerNM_itemCD", name: "【センター小計】倉庫名 → 商品コード順", option1: "centerNM", option2:"centerCD", option3: "itemCD", option4: ""}),
];

export const optionAll = new CodeName({ code: '', name: '全て' });
